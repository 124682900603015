<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Advances
            <v-spacer></v-spacer>
            <print-buttons
              labelPdf="Download Pdf"
              labelExcel="Download Excel"
              :passAuthToReport="true"
              v-if="downloadUrl"
              :download-url="downloadUrl"
              :pdf="true"
              :excel="true"
            ></print-buttons>

            <transition
              v-if="advances.length > 0"
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <add-button
                v-if="grade !== 'all'"
                :permission="'journal-advance-pay'"
                style="margin:5px"
                @action="dialog = true"
              >
                Pay
              </add-button>
            </transition>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs4 sm4>
                <v-select
                  :disabled="gradeLoading"
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            :footer-props="footerProps"
            :items="form.items.data"
            :server-items-length="form.items.meta.total"
            :options.sync="pagination"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td class="text-xs-left">{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.enroll_code }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">
                  {{ item.grade }}<sup>"{{ item.section }}"</sup>
                </td>
                <td class="text-xs-left">
                  <span>
                    <input
                      type="number"
                      class="inputType"
                      :disabled="
                        !advanceShowToOnlySchool($auth.company('id')) ||
                          (item.advance && item.advance.update_stamp === 1)
                      "
                      v-model="item.advance_amount"
                    />
                  </span>
                </td>
                <td>
                  <v-tooltip left color="success">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="saveAdvance(item)"
                        :disabled="
                          item.advance && item.advance.update_stamp === 1
                        "
                        v-if="$auth.can('journal-advance-pay')"
                        permission="fee-update"
                        small
                        icon
                        color="success"
                        class="pa-0 ma-1"
                        slot="activator"
                      >
                        <v-icon small v-on="on">{{ "save" }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Save</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template v-slot:body.append>
              <tr>
                <th :colspan="4" class="text-xs-right">Total:</th>
                <th :colspan="2" class="text-xs-center">
                  {{ totalAdvanceAmount.currency() }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="dialog" persistent max-width="300">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0"
            >Are you sure want to pay Advance?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="dialog = false"
              >No</v-btn
            >
            <v-btn color="warning" outlined small @click="pay()">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import NavFactory from "@/library/NavFactory";

export default {
  mixins: [Mixins],
  data: () => ({
    gradeLoading: false,
    adv_amount: "",
    form: new Form(
      {
        amount: "",
        enroll_code: "",
        stat: 0,
      },
      "/api/advance"
    ),
    pagination: {
      rowsPerPage: 300,
    },
    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      {
        text: "Enroll Code",
        align: "left",
        value: "enroll_code",
        sortable: false,
      },
      { text: "Name", align: "left", value: "name", sortable: false },
      { text: "Grade", align: "left", value: "grade", sortable: true },
      { text: "Amount", align: "left", value: "amount", sortable: false },
      { text: "Action", align: "left", value: "action", sortable: false },
    ],
    grades: [],
    grade: "",
    enrollCode: "",
    sections: [],
    section: "",
    dialog: false,
    verify: 0,
    unverify: 0,
    accountServiceStatus: false,
    createJournal: false,
    alreadyJournal: false,
    totalAmount: 0,
    reasonDialog: false,
    narration: "",
    advances: [],
    payStatus: 0,
    totalAdvanceAmount: 0,
    downloadUrl: "",
  }),

  computed: {
    ...mapState(["batch"]),
  },

  mounted() {
    this.getGrades();
    // this.get();
    if (this.$auth.getServices("ACCOUNT")) this.accountServiceStatus = true;
    else this.accountServiceStatus = false;
  },

  watch: {
    // pagination: function() {
    //   this.get();
    // },
    // batch: function(value) {
    //   this.get();
    // },
    grade: function() {
      this.get();
    },
  },

  methods: {
    advanceShowToOnlySchool: NavFactory.advanceShowToOnlySchool,
    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&gradeId=" +
            this.grade
        )
        .then(({ data }) => {
          this.grades.push({ value: "all", text: "ALL" });
          data.data.map((item) => {
            this.grades.push({ value: item.id, text: item.name });
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },

    get(params) {
      if (this.grade) {
        let extraParams = "gradeId=" + this.grade;
        this.form.get(null, this.queryString(extraParams)).then(({ data }) => {
          this.advances = [];
          this.totalAdvanceAmount = 0;
          data.data.map((res) => {
            this.totalAdvanceAmount += res.advance ? res.advance.amount : 0;
            this.payStatus = data.pay_status;
            if (res.advance) {
              this.advances.push({
                id: res.advance.id,
                enroll_code: res.enroll_code,
                enroll_id: res.advance.enroll_id,
                amount: res.advance.amount,
              });
            }
          });
          this.downloadUrl = data.download_url;
          this.pagination.totalItems = data.meta.total;
        });
      }
    },
    pay() {
      this.$rest
        .post("/api/advance-post", {
          advances: this.advances,
          grade_id: this.grade,
          // account_service_status: this.accountServiceStatus,
          account_service_status: true,
        })
        .then(({ data }) => {
          this.$events.fire("notification", {
            message: data.message,
            status: "success",
          });
          this.dialog = false;
          this.get();
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
    saveAdvance(item) {
      let dataToSend = [];
      dataToSend.push(item);
      if (item.advance_amount) {
        this.$rest
          .post("/api/advance", {
            enroll_id: item.enroll_id,
            amount: item.advance_amount,
          })
          .then((res) => {
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
            this.get();
          })
          .catch((e) => {
            this.$events.fire("notification", {
              message: e.response.data.message,
              status: "error",
            });
          });
      }
    },
  },
};
</script>
<style lang="scss">
.inputType {
  border: 1px solid #bbb;
  text-align: center;
  height: 34px;
  width: 86px;

  &:focus {
    outline: none;
  }

  &[disabled] {
    color: #999;
  }
}

.inputType:disabled {
  cursor: not-allowed;
  background: #eee;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
